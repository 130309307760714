import { Flex } from 'component-library/components/layout/Flex';
import { Heading } from 'component-library/components/typography/Heading';
import { device } from 'component-library/styles/device';
import { graphql } from 'gatsby';
import {
  ArticleGridFragmentFragment,
  ContentVariablesFragment,
  RelatedArticlesFragmentFragment as RelatedArticlesFragmentTypes,
} from 'graphqlTypes';
import { ArticleGrid } from 'page-components/advice/article-grid/ArticleGrid';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  padding: 64px 0 80px;

  ${`@media ${device.lg}`} {
    padding: 88px 0;
  }
`;

interface RelatedArticlesProps {
  blogPost: RelatedArticlesFragmentTypes;
  contentVariables: ContentVariablesFragment[];
}

export const RelatedArticlesFragment = graphql`
  fragment RelatedArticlesFragment on ContentfulBlogPost {
    id
    relatedBlogPost {
      ...ArticleGridFragment
    }
  }
`;

export const RelatedPosts: FC<RelatedArticlesProps> = ({
  blogPost,
  contentVariables,
}) =>
  blogPost.relatedBlogPost ? (
    <Wrapper column>
      <Heading marginBottom={32} variant={4}>
        Related Posts
      </Heading>
      <ArticleGrid
        articles={blogPost.relatedBlogPost as ArticleGridFragmentFragment[]}
        contentVariables={contentVariables}
      />
    </Wrapper>
  ) : null;
