import { Flex } from 'component-library/components/layout/Flex';
import { Body, BodyProps } from 'component-library/components/typography/Body';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BylineFragment as BylineFragmentTypes } from 'graphqlTypes';
import { formatDate } from 'helpers/formatDate';
import React, { FC } from 'react';
import styled from 'styled-components';

export const BylineFragment = graphql`
  fragment Byline on ContentfulBlogPost {
    blogAuthor {
      name
      photo {
        gatsbyImageData
        title
      }
    }
    displayedPublishDate
    updatedOnDate
  }
`;

const SmallWrapper = styled(Flex)`
  gap: 16px;
`;

const Bullet = () => (
  <Body
    medium
    variant={4}
    color={colors.forestGreen}
    marginLeft={12}
    marginRight={12}
  >
    &bull;
  </Body>
);

interface AuthorNameProps extends Omit<BodyProps, 'variant'> {
  name: string;
}

const AuthorName: FC<AuthorNameProps> = ({ name, ...rest }) => (
  <Body medium color={colors.forestGreen} {...rest} variant={4}>
    {name}
  </Body>
);

interface UpdatedOnDateProps extends Omit<BodyProps, 'variant'> {
  date: string;
}

const UpdatedOnDate: FC<UpdatedOnDateProps> = ({ date, ...rest }) => (
  <Body medium variant={4} color={colors.forestGreen} {...rest}>
    Updated {formatDate(new Date(date))}
  </Body>
);

interface PublishedOnDateProps extends Omit<BodyProps, 'variant'> {
  date: string;
}

const PublishedOnDate: FC<PublishedOnDateProps> = ({ date, ...rest }) => (
  <Body variant={4} medium color={colors.forestGreen} {...rest}>
    Published {formatDate(new Date(date))}
  </Body>
);

interface BylineProps {
  blogPost: BylineFragmentTypes;
}

export const Byline: FC<BylineProps> = ({ blogPost }) => {
  const { blogAuthor, displayedPublishDate, updatedOnDate } = blogPost;

  return (
    <>
      <ShowOn screens={[Screen.Sm, Screen.Md, Screen.Lg]}>
        <SmallWrapper marginBottom={40}>
          {blogAuthor?.photo?.gatsbyImageData && (
            <GatsbyImage
              image={blogAuthor.photo.gatsbyImageData}
              alt={blogAuthor.photo.title || ''}
              style={{ borderRadius: '50%', width: '48px', height: '48px' }}
            />
          )}
          <Flex column>
            {blogAuthor?.name && (
              <AuthorName name={blogAuthor?.name} marginBottom={16} />
            )}
            <PublishedOnDate date={displayedPublishDate} marginBottom={16} />
            {updatedOnDate && <UpdatedOnDate date={updatedOnDate} />}
          </Flex>
        </SmallWrapper>
      </ShowOn>
      <ShowOn screens={[Screen.Xl, Screen.Xxl]}>
        <Flex alignItems="center" marginBottom={40}>
          {blogAuthor?.photo?.gatsbyImageData && (
            <GatsbyImage
              image={blogAuthor.photo.gatsbyImageData}
              alt={blogAuthor.photo.title || ''}
              style={{
                borderRadius: '50%',
                width: '48px',
                height: '48px',
                marginRight: '16px',
              }}
            />
          )}
          {blogAuthor?.name && (
            <>
              <AuthorName name={blogAuthor?.name} />
              <Bullet />
            </>
          )}
          <PublishedOnDate date={displayedPublishDate} />
          {updatedOnDate && (
            <>
              <Bullet />
              <UpdatedOnDate date={updatedOnDate} />
            </>
          )}
        </Flex>
      </ShowOn>
    </>
  );
};
