import { retirablePhoneNumber } from 'helpers/businessConstants';
import React, { FC, PropsWithChildren } from 'react';

interface OutboundCallLinkProps {
  phoneNumber?: string;
}

export const OutboundCallLink: FC<PropsWithChildren<OutboundCallLinkProps>> = ({
  phoneNumber = retirablePhoneNumber,
  children,
}) => <a href={`tel:${phoneNumber}`}>{children}</a>;
