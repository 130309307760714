import { Circle, Icon } from 'component-library/components/icons';
import { IconName } from 'component-library/components/icons/Icon';
import { Flex } from 'component-library/components/layout/Flex';
import { Link } from 'component-library/components/links/Link';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import { graphql } from 'gatsby';
import {
  ContentVariablesFragment,
  GuideFragmentFragment as GuideFragmentTypes,
} from 'graphqlTypes';
import { normalizeUrl } from 'helpers/normalizeUrlNew';
import { replaceContentVariables } from 'helpers/replaceContentVariables';
import { HorizontalRule } from 'page-components/advice/horizontal-rule/HorizontalRule';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const SubTopic = styled(Flex)`
  padding: 24px 0 18px;
  cursor: pointer;
`;

interface GuideProps {
  subTopics?: (GuideFragmentTypes | null | undefined)[] | null;
  contentVariables: ContentVariablesFragment[];
}

export const GuideFragment = graphql`
  fragment GuideFragment on ContentfulGuideSubTopic {
    id
    guideSubTopicTitle
    blogPosts {
      guideAlternativeName
      title
      primaryBlogCategory {
        title
      }
      slug
    }
  }
`;

export const Guide: FC<GuideProps> = ({ subTopics, contentVariables }) => {
  if (!subTopics) return null;

  const [openSubTopicIndex, updateOpenSubTopicIndex] = useState<
    null | number
  >();

  return (
    <Flex column>
      {subTopics.map((subTopic, idx) => (
        <Flex
          key={subTopic?.id}
          onClick={() =>
            updateOpenSubTopicIndex((prev) => (prev === idx ? null : idx))
          }
          column
        >
          <Flex column>
            <SubTopic justifyContent="space-between" alignItems="center">
              <Body variant={3} color={colors.white}>
                {replaceContentVariables(
                  subTopic?.guideSubTopicTitle,
                  contentVariables,
                )}
              </Body>
              <Circle small backgroundColor={colors.peach} marginLeft={12}>
                <Icon
                  name={
                    openSubTopicIndex === idx
                      ? IconName.ChevronUp
                      : IconName.ChevronDown
                  }
                  color={colors.navyBlue}
                />
              </Circle>
            </SubTopic>
          </Flex>
          {openSubTopicIndex === idx &&
            subTopic?.blogPosts?.map((bp, idx) => (
              <Link
                to={normalizeUrl(
                  `/advice/${bp?.primaryBlogCategory?.title}/${bp?.slug}`,
                )}
                key={bp?.slug}
                variant={4}
                underline
                color={colors.white}
                marginBottom={idx + 1 === subTopic.blogPosts?.length ? 24 : 8}
              >
                {replaceContentVariables(bp?.title, contentVariables)}
              </Link>
            ))}
          {idx !== subTopics.length - 1 && (
            <HorizontalRule color={colors.white} />
          )}
        </Flex>
      ))}
    </Flex>
  );
};
