import { Flex, FlexProps } from 'component-library/components/layout/Flex';
import { Subheading } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import { device } from 'component-library/styles/device';
import { graphql } from 'gatsby';
import {
  ContentVariablesFragment,
  DesktopGuideFragmentFragment as DesktopGuideFragmentTypes,
} from 'graphqlTypes';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { Guide } from './Guide';

interface WrapperProps {
  includeNegativeMargin?: boolean;
}

const Wrapper = styled(Flex)<WrapperProps>`
  ${({ includeNegativeMargin = true }) =>
    includeNegativeMargin &&
    css`
      ${`@media ${device.sm}`} {
        margin-left: -20px;
        margin-right: -20px;
      }
    `}

  border-radius: 16px;
  background: ${colors.navyBlue};
  padding: 40px 40px 32px;
`;

export const DesktopGuideFragment = graphql`
  fragment DesktopGuideFragment on ContentfulGuideSubTopic {
    guidetopic {
      id
      guideTopicTitle
      subTopics {
        ...GuideFragment
      }
    }
  }
`;

interface DesktopGuideProps extends WrapperProps, FlexProps {
  guideSubTopic?: (DesktopGuideFragmentTypes | null | undefined)[] | null;
  contentVariables: ContentVariablesFragment[];
}

export const DesktopGuide: FC<DesktopGuideProps> = ({
  guideSubTopic,
  contentVariables,
  includeNegativeMargin,
  ...rest
}) => {
  if (
    !guideSubTopic ||
    !guideSubTopic[0] ||
    !guideSubTopic[0].guidetopic ||
    !guideSubTopic[0].guidetopic[0]
  )
    return null;

  const guideTopic = guideSubTopic[0].guidetopic[0];
  const subTopics = guideTopic.subTopics;

  return (
    <Wrapper
      column
      marginBottom={40}
      includeNegativeMargin={includeNegativeMargin}
      {...rest}
    >
      <Subheading marginBottom={8} color={colors.white} variant={1}>
        {guideTopic.guideTopicTitle}
      </Subheading>
      <Guide subTopics={subTopics} contentVariables={contentVariables} />
    </Wrapper>
  );
};
