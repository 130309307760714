import { Flex } from 'component-library/components/layout/Flex';
import { Link } from 'component-library/components/links/Link';
import { Body } from 'component-library/components/typography/Body';
import { normalizeUrl } from 'helpers/normalizeUrlNew';
import { titleize } from 'helpers/titleize';
import React, { FC } from 'react';
import styled from 'styled-components';

interface BreadcrumbsProps {
  categoryTitle?: string | null;
  location?: string;
}

const Carat = styled(Body)`
  margin: 0 6px;
`;

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  categoryTitle,
  location = 'advice',
}) => (
  <Flex alignItems="center" marginBottom={40}>
    <Link medium variant={2} to={`/${location}`}>
      {titleize(location)}
    </Link>
    {categoryTitle && (
      <>
        <Carat medium variant={2}>
          ›
        </Carat>
        <Link
          medium
          variant={2}
          to={normalizeUrl(`/${location}/${categoryTitle}`)}
        >
          {titleize(categoryTitle)}
        </Link>
      </>
    )}
  </Flex>
);
