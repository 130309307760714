const assignAppropriateDateValue = (num: number) => {
  return num > 0
    ? ['th', 'st', 'nd', 'rd'][
        (num > 3 && num < 21) || num % 10 > 3 ? 0 : num % 10
      ]
    : '';
};

export const formatDate = (dateObject: Date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = dateObject.getDate();

  return `${months[dateObject.getMonth()]} ${date}${assignAppropriateDateValue(
    date,
  )}, ${dateObject.getFullYear()} `;
};
