import { graphql } from 'gatsby';
import { BlogAdFragment } from 'graphqlTypes';
import React, { FC } from 'react';

import { BlogBottomAdLarge } from './BlogBottomAdLarge';
import { BlogBottomAdSmall } from './BlogBottomAdSmall';

export interface BlogBottomAdProps {
  blogBottomAd?: BlogAdFragment | null;
}

export const BlogBottomAd: FC<BlogBottomAdProps> = (props) => (
  <>
    <BlogBottomAdSmall {...props} />
    <BlogBottomAdLarge {...props} />
  </>
);

export const ArticleBlogBottomAdFragment = graphql`
  fragment ArticleBlogBottomAd on ContentfulBlogPost {
    blogBottomAd {
      ...BlogAd
    }
  }
`;
