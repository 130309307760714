import {
  Heading,
  headings,
} from 'component-library/components/typography/Heading';
import { device } from 'component-library/styles/device';
import styled from 'styled-components';

interface ResponsiveHeadingProps {
  maxWidth?: number;
}

const responsiveHeadingStyles = {
  1: {
    sm: '32px',
    md: '32px',
    lg: '40px',
    xl: '48px',
    xxl: '64px',
    xxxl: '80px',
  },
  2: {
    sm: '40px',
    md: '40px',
    lg: '40px',
    xl: '48px',
    xxl: '56px',
    xxxl: '72px',
  },
  3: {
    sm: headings[4].fontSize,
    md: headings[4].fontSize,
    lg: headings[4].fontSize,
    xl: headings[4].fontSize,
    xxl: headings[4].fontSize,
    xxxl: headings[4].fontSize,
  },
  4: {
    sm: headings[4].fontSize,
    md: headings[4].fontSize,
    lg: headings[4].fontSize,
    xl: headings[4].fontSize,
    xxl: headings[4].fontSize,
    xxxl: headings[4].fontSize,
  },
  5: {
    sm: headings[4].fontSize,
    md: headings[4].fontSize,
    lg: headings[5].fontSize,
    xl: headings[5].fontSize,
    xxl: headings[5].fontSize,
    xxxl: headings[5].fontSize,
  },
};

export const ResponsiveHeading = styled(Heading)<ResponsiveHeadingProps>`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};
  
  ${`@media ${device.sm}`} {
    font-size: ${({ variant }) => responsiveHeadingStyles[variant].sm};
  }

  ${`@media ${device.md}`} {
    font-size: ${({ variant }) => responsiveHeadingStyles[variant].md};
  }

  ${`@media ${device.lg}`} {
    font-size: ${({ variant }) => responsiveHeadingStyles[variant].lg};
  }

  ${`@media ${device.xl}`} {
    font-size: ${({ variant }) => responsiveHeadingStyles[variant].xl};
  }

  ${`@media ${device.xxl}`} {
    font-size: ${({ variant }) => responsiveHeadingStyles[variant].xxl};
  }

  ${`@media ${device.xxxl}`} {
    font-size: ${({ variant }) => responsiveHeadingStyles[variant].xxxl};
  }

  ${`@media ${device.xxxxl}`} {
    font-size: ${({ variant }) => headings[variant].fontSize};
  }
`;
