import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import { graphql } from 'gatsby';
import {
  ContentVariablesFragment,
  KeyTakeawaysFragmentFragment as KeyTakeawaysFragmentTypes,
} from 'graphqlTypes';
import { replaceContentVariables } from 'helpers/replaceContentVariables';
import React, { FC } from 'react';
import styled from 'styled-components';

export const KeyTakeawaysFragment = graphql`
  fragment KeyTakeawaysFragment on ContentfulBlogPost {
    summary
  }
`;

interface KeyTakeawaysProps {
  blogPost: KeyTakeawaysFragmentTypes;
  contentVariables: ContentVariablesFragment[];
}

const KeyTakeawaysWrapper = styled(Flex)`
  background-color: ${colors.teal};
  padding: 32px;
  border-radius: 16px;
`;

export const KeyTakeaways: FC<KeyTakeawaysProps> = ({
  blogPost,
  contentVariables,
}) => {
  const { summary } = blogPost;
  if (!summary) return null;

  return (
    <KeyTakeawaysWrapper column marginBottom={64}>
      <Body variant={1} medium marginBottom={16}>
        Key Takeaways
      </Body>
      {summary.map((s, idx) => (
        <Flex key={idx} marginBottom={8} alignItems="baseline">
          &bull;
          <Body variant={3} marginBottom={8} marginLeft={4}>
            {replaceContentVariables(s, contentVariables)}
          </Body>
        </Flex>
      ))}
    </KeyTakeawaysWrapper>
  );
};
