import 'markdown.scss';

import Layout from 'common/layouts/Layout';
import { Flex } from 'component-library/components/layout/Flex';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { device } from 'component-library/styles/device';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Author } from 'components/pages/blog-post-page/Author';
import { BlogPostHeading } from 'components/pages/blog-post-page/BlogPostHeading';
import { Byline } from 'components/pages/blog-post-page/Byline';
import { DesktopGuide } from 'components/pages/blog-post-page/DesktopGuide';
import { KeyTakeaways } from 'components/pages/blog-post-page/KeyTakeaways';
import { RelatedPosts } from 'components/pages/blog-post-page/RelatedArticles';
import { SocialSharingLinks } from 'components/pages/blog-post-page/SocialSharingLinks';
import { TableOfContents } from 'components/pages/blog-post-page/TableOfContents';
import { SEO } from 'components/SEO';
import { graphql, HeadProps } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { BlogPostQuery } from 'graphqlTypes';
import { normalizeUrl } from 'helpers/normalizeUrlNew';
import { replaceContentVariables } from 'helpers/replaceContentVariables';
import {
  ArticleWrapper,
  HorizontalRule,
} from 'page-components/advice';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';
import styled from 'styled-components';

import { BlogBottomAd } from './blog-ads/blog-bottom-ad';
import { BlogAd } from './blog-ads/blogAd';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';

interface ArticleProps {
  data: BlogPostQuery;
}

const ShowOnDesktop = styled(ShowOn).attrs({
  screens: [Screen.Lg, Screen.Xl, Screen.Xxl],
})``;

const ShowOnMobile = styled(ShowOn).attrs({
  screens: [Screen.Sm, Screen.Md],
})``;

const LeftAndRightPaneWrapper = styled(Flex)`
  gap: 80px;
`;

const LeftPanel = styled(Flex)`
  max-width: 100%;

  ${`@media ${device.lg}`} {
    flex-basis: 63%;
    max-width: 63%;
  }
`;
const RightPanel = styled(Flex)`
  display: none;

  ${`@media ${device.lg}`} {
    display: unset;
    flex-basis: 37%;
  }
`;

const Article: FC<ArticleProps> = ({ data }) => {
  const blogPost = data.contentfulBlogPost;
  if (!blogPost) return null;

  const {
    slug,
    content,
    primaryBlogCategory,
    blogBottomAd,
    blogAd,
    guidesubtopic,
  } = blogPost;

  const contentVariables = data.contentVariables.nodes;

  return (
    <Layout>
      <PageWrapper background={colors.greenLight}>
        <Navigation />
        <ArticleWrapper>
          <Breadcrumbs categoryTitle={primaryBlogCategory?.title} />
          <LeftAndRightPaneWrapper>
            <LeftPanel column>
              <BlogPostHeading
                blogPost={blogPost}
                contentVariables={contentVariables}
              />
              <Byline blogPost={blogPost} />
              <TableOfContents
                spacing={2}
                blogPost={blogPost}
                contentVariables={contentVariables}
              />
              <KeyTakeaways
                blogPost={blogPost}
                contentVariables={contentVariables}
              />
              <div className="article">
                {content?.childMdx?.body && (
                  <MDXRenderer>
                    {replaceContentVariables(
                      content?.childMdx.body,
                      contentVariables,
                    ) || ''}
                  </MDXRenderer>
                )}
              </div>
              <ShowOnDesktop>
                <SocialSharingLinks
                  url={normalizeUrl(
                    `https://retirable.com/advice/${primaryBlogCategory?.title}/${slug}`,
                  )}
                />
                <Author blogPost={blogPost} />
              </ShowOnDesktop>
            </LeftPanel>
            <RightPanel column>
              <DesktopGuide
                marginBottom={80}
                guideSubTopic={guidesubtopic}
                contentVariables={contentVariables}
              />

              <BlogAd blogAd={blogAd} />
            </RightPanel>
          </LeftAndRightPaneWrapper>
          <ShowOnDesktop>
            <HorizontalRule />
            <RelatedPosts
              blogPost={blogPost}
              contentVariables={contentVariables}
            />
          </ShowOnDesktop>
        </ArticleWrapper>
        <ShowOnMobile>
          <DesktopGuide
            marginTop={48}
            guideSubTopic={guidesubtopic}
            contentVariables={contentVariables}
            includeNegativeMargin={false}
          />
          <ArticleWrapper>
            <SocialSharingLinks
              url={normalizeUrl(
                `https://retirable.com/advice/${primaryBlogCategory?.title}/${slug}`,
              )}
            />
            <Author blogPost={blogPost} />
            <RelatedPosts
              blogPost={blogPost}
              contentVariables={contentVariables}
            />
          </ArticleWrapper>
        </ShowOnMobile>
        <BlogBottomAd blogBottomAd={blogBottomAd} />
        <Footer />
      </PageWrapper>
    </Layout>
  );
};

export const query = graphql`
  query BlogPost($pathSlug: String!) {
    contentfulBlogPost(slug: { eq: $pathSlug }) {
      id
      slug
      content {
        childMdx {
          body
        }
      }
      primaryBlogCategory {
        title
      }
      ...Byline
      ...ArticleBlogAd
      ...ArticleSEO
      ...BlogPostHeadingFragment
      ...TableOfContentsFragment
      ...KeyTakeawaysFragment
      ...AuthorFragment
      ...RelatedArticlesFragment
      ...ArticleBlogBottomAd
      guidesubtopic {
        ...DesktopGuideFragment
      }
    }
    contentVariables: allContentfulContentVariables {
      nodes {
        ...ContentVariables
      }
    }
  }
`;

export const ArticleSEO = graphql`
  fragment ArticleSEO on ContentfulBlogPost {
    slug
    seoTitle
    title
    seoDescription
    displayedPublishDate
    excerpt {
      excerpt
    }
    image {
      gatsbyImageData
    }
    blogAuthor {
      name
    }
  }
`;

export default Article;

export const Head: FC<HeadProps<BlogPostQuery>> = ({ data }) => {
  const blogPost = data.contentfulBlogPost;
  if (!blogPost) return null;

  const {
    seoTitle,
    title,
    seoDescription,
    excerpt,
    image,
    slug,
    blogAuthor,
    displayedPublishDate,
  } = blogPost;

  const contentVariables = data.contentVariables.nodes;

  const imagePath = image ? getSrc(image) : '';

  const titleWithContentVariablesReplaced = replaceContentVariables(
    title,
    contentVariables,
  );

  const seoTitleWithContentVariablesReplaced = replaceContentVariables(
    seoTitle,
    contentVariables,
  );

  return (
    <SEO
      title={
        seoTitleWithContentVariablesReplaced ||
        titleWithContentVariablesReplaced ||
        ''
      }
      description={
        replaceContentVariables(seoDescription, contentVariables) ||
        replaceContentVariables(excerpt?.excerpt, contentVariables) ||
        ''
      }
      imagePath={imagePath}
      article={{
        title:
          seoTitleWithContentVariablesReplaced ||
          titleWithContentVariablesReplaced,
        author: blogAuthor?.name,
        slug,
        displayedPublishDate,
        imageUrl: imagePath,
      }}
    />
  );
};
