import { OutboundCallLinkButton } from 'common/links/OutboundCallLinkButton';
import { PrimaryButton } from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { getColorScheme } from '../shared';
import { BlogBottomAdProps } from '.';

const Wrapper = styled(ShowOn)<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 32px;
  padding: 64px 20px;
  margin-bottom: 32px;
`;

export const BlogBottomAdSmall: FC<BlogBottomAdProps> = ({ blogBottomAd }) => {
  if (!blogBottomAd) return null;

  const {
    title,
    message,
    buttonUrl,
    buttonCopy,
    image,
    showCallNowButton = true,
    backgroundColor,
  } = blogBottomAd;

  const colorScheme = getColorScheme(backgroundColor);

  return (
    <Wrapper
      backgroundColor={colorScheme.backgroundColor}
      screens={[Screen.Sm, Screen.Md]}
    >
      <Heading variant={4} marginBottom={16} color={colorScheme.fontColor}>
        {title}
      </Heading>
      <Body variant={2} marginBottom={32} color={colorScheme.fontColor}>
        {message?.message}
      </Body>
      <LinkWrapper to={buttonUrl || '/welcome'}>
        <PrimaryButton marginBottom={16} buttonColor={colorScheme.buttonColor}>
          {buttonCopy}
        </PrimaryButton>
      </LinkWrapper>
      {showCallNowButton && <OutboundCallLinkButton />}
      <Flex marginTop={40} column>
        {image ? (
          <GatsbyImage image={image.gatsbyImageData} alt="Ad image" />
        ) : (
          <StaticImage
            src="../images/article-bottom-cta.png"
            alt="personal-plan"
          />
        )}
      </Flex>
    </Wrapper>
  );
};
