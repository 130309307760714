import { Icon } from 'component-library/components/icons';
import { IconName } from 'component-library/components/icons/Icon';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import { HorizontalRule } from 'page-components/advice/horizontal-rule/HorizontalRule';
import React, { FC } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

interface SocialSharingLinksProps {
  url: string;
}

export const SocialSharingLinks: FC<SocialSharingLinksProps> = ({ url }) => (
  <Flex column marginTop={64}>
    <HorizontalRule />
    <Flex marginTop={32} marginBottom={32} column alignItems="flex-end">
      <Flex alignItems="center">
        <Body variant={3} marginRight={16}>
          Share this advice
        </Body>
        <FacebookShareButton url={url}>
          <Icon
            backgroundColor={colors.peach}
            color={colors.navyBlue}
            marginRight={16}
            name={IconName.Facebook}
          />
        </FacebookShareButton>
        <LinkedinShareButton url={url}>
          <Icon
            backgroundColor={colors.peach}
            color={colors.navyBlue}
            marginRight={16}
            name={IconName.LinkedIn}
          />
        </LinkedinShareButton>
        <TwitterShareButton url={url}>
          <Icon
            backgroundColor={colors.peach}
            color={colors.navyBlue}
            marginRight={16}
            name={IconName.Twitter}
          />
        </TwitterShareButton>
      </Flex>
    </Flex>
    <HorizontalRule />
  </Flex>
);
