import { OutboundCallLinkButton } from 'common/links/OutboundCallLinkButton';
import { PrimaryButton } from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { BlogAdFragment } from 'graphqlTypes';
import React, { FC } from 'react';
import styled from 'styled-components';

import { getColorScheme } from './shared';

const BlogAdWrapper = styled(Flex)<{ backgroundColor: string }>`
  border-radius: 16px;
  padding: 40px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ImageWrapper = styled(Flex)`
  padding: 0 40px;
  margin-top: -80px;
`;

interface BlogAdProps {
  blogAd?: BlogAdFragment | null;
}

export const BlogAd: FC<BlogAdProps> = ({ blogAd }) => {
  if (!blogAd) return null;

  const {
    title,
    message,
    buttonUrl,
    buttonCopy,
    image,
    showCallNowButton = true,
    backgroundColor,
  } = blogAd;

  const colorScheme = getColorScheme(backgroundColor);

  return (
    <BlogAdWrapper backgroundColor={colorScheme.backgroundColor} column>
      <ImageWrapper marginBottom={40}>
        {image ? (
          <GatsbyImage image={image.gatsbyImageData} alt="Ad image" />
        ) : (
          <StaticImage
            src="./images/call-to-action.png"
            alt="Income and expenses charts"
          />
        )}
      </ImageWrapper>
      <Heading variant={4} marginBottom={12} color={colorScheme.fontColor}>
        {title}
      </Heading>
      <Body variant={2} marginBottom={32} color={colorScheme.fontColor}>
        {message?.message}
      </Body>
      <LinkWrapper to={buttonUrl || '/welcome'}>
        <PrimaryButton marginBottom={16} buttonColor={colorScheme.buttonColor}>
          {buttonCopy}
        </PrimaryButton>
      </LinkWrapper>
      {showCallNowButton && <OutboundCallLinkButton />}
    </BlogAdWrapper>
  );
};

export const ArticleBlogAdFragment = graphql`
  fragment ArticleBlogAd on ContentfulBlogPost {
    blogAd {
      ...BlogAd
    }
  }
`;
