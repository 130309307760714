import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { device } from 'component-library/styles/device';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { AuthorFragmentFragment as AuthorFragmentTypes } from 'graphqlTypes';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  padding-top: 48px;
  flex-direction: column;
  gap: 32px;

  ${`@media ${device.lg}`} {
    padding: 64px 0 56px;
    flex-direction: row;
    gap: 40px;
  }
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 50%;
  width: 128px;
  min-width: 128px;
  height: 128px;
  min-height: 128px;
`;

interface AuthorProps {
  blogPost: AuthorFragmentTypes;
}

export const AuthorFragment = graphql`
  fragment AuthorFragment on ContentfulBlogPost {
    blogAuthor {
      name
      photo {
        gatsbyImageData
      }
      bio {
        childMdx {
          body
        }
      }
    }
  }
`;

export const Author: FC<AuthorProps> = ({ blogPost }) => {
  const { blogAuthor } = blogPost;
  if (!blogAuthor || !blogAuthor.bio?.childMdx?.body) return null;

  return (
    <Wrapper>
      <StyledImage
        image={blogAuthor.photo?.gatsbyImageData}
        alt={blogAuthor.name || ''}
      />
      <Flex column>
        <Subheading variant={2} medium marginBottom={16}>
          {blogAuthor.name}
        </Subheading>
        <Body variant={3}>
          <MDXRenderer>{blogAuthor.bio?.childMdx?.body}</MDXRenderer>
        </Body>
      </Flex>
    </Wrapper>
  );
};
