import { OutboundCallLinkButton } from 'common/links/OutboundCallLinkButton';
import { PrimaryButton } from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Body } from 'component-library/components/typography/Body';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { getColorScheme } from '../shared';
import { BlogBottomAdProps } from '.';

const Wrapper = styled(ShowOn)`
  max-width: 1568px;
  padding: 0 40px;
  margin: auto;
  margin-bottom: 40px;
`;

const Panel = styled(Flex)<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 32px;
`;

const Content = styled(Flex)`
  max-width: 1414px;
  padding: 60px 40px;
  gap: 40px;
  margin: auto;
`;

const Image = styled(Flex)`
  flex-basis: 45%;
`;

export const BlogBottomAdLarge: FC<BlogBottomAdProps> = ({ blogBottomAd }) => {
  if (!blogBottomAd) return null;

  const {
    title,
    message,
    buttonUrl,
    buttonCopy,
    image,
    showCallNowButton = true,
    backgroundColor,
  } = blogBottomAd;

  const colorScheme = getColorScheme(backgroundColor);

  return (
    <Wrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
      <Panel column backgroundColor={colorScheme.backgroundColor}>
        <Content alignItems="center">
          <Flex column>
            <ResponsiveHeading
              marginBottom={8}
              variant={3}
              color={colorScheme.fontColor}
            >
              {title}
            </ResponsiveHeading>
            <Body variant={2} marginBottom={40} color={colorScheme.fontColor}>
              {message?.message}
            </Body>
            <Flex>
              <LinkWrapper to={buttonUrl || '/welcome'}>
                <PrimaryButton
                  marginBottom={16}
                  buttonColor={colorScheme.buttonColor}
                >
                  {buttonCopy}
                </PrimaryButton>
              </LinkWrapper>
              {showCallNowButton && <OutboundCallLinkButton marginLeft={16} />}
            </Flex>
          </Flex>
          <Image column>
            {image ? (
              <GatsbyImage image={image.gatsbyImageData} alt="Ad image" />
            ) : (
              <StaticImage
                src="../images/article-bottom-cta.png"
                alt="personal-plan"
              />
            )}
          </Image>
        </Content>
      </Panel>
    </Wrapper>
  );
};
