import { OutboundCallLink } from 'common/links/OutBoundCallLink';
import {
  ButtonColor,
  PrimaryButton,
  PrimaryButtonProps,
} from 'component-library/components/buttons/PrimaryButton';
import React, { FC } from 'react';

interface OutboundCallLinkButtonProps
  extends Omit<PrimaryButtonProps, 'buttonColor'> {
  phoneNumber?: string;
}

export const OutboundCallLinkButton: FC<OutboundCallLinkButtonProps> = ({
  phoneNumber,
  ...rest
}) => (
  <OutboundCallLink phoneNumber={phoneNumber}>
    <PrimaryButton buttonColor={ButtonColor.Transparent} {...rest}>
      Call Now
    </PrimaryButton>
  </OutboundCallLink>
);
