import { Base, BaseProps } from 'common/layouts/Base';
import { Circle, Icon } from 'component-library/components/icons';
import { IconName } from 'component-library/components/icons/Icon';
import { Flex } from 'component-library/components/layout/Flex';
import { Link } from 'component-library/components/links/Link';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import { graphql } from 'gatsby';
import Slugger from 'github-slugger';
import {
  ContentVariablesFragment,
  TableOfContentsFragmentFragment as TableOfContentsFragmentTypes,
} from 'graphqlTypes';
import { normalizeUrl } from 'helpers/normalizeUrlNew';
import { replaceContentVariables } from 'helpers/replaceContentVariables';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

export const TableOfContentsFragment = graphql`
  fragment TableOfContentsFragment on ContentfulBlogPost {
    id
    slug
    primaryBlogCategory {
      title
    }
    content {
      childMdx {
        headings {
          depth
          value
        }
      }
    }
  }
`;

const TableOfContentsWrapper = styled(Base)`
  background-color: ${colors.teal};
  padding: 32px;
  border-radius: 16px;
`;

interface TableOfContentsProps extends BaseProps {
  blogPost: TableOfContentsFragmentTypes;
  contentVariables: ContentVariablesFragment[];
}

export const TableOfContents: FC<TableOfContentsProps> = ({
  blogPost,
  contentVariables,
  ...rest
}) => {
  const slugger = new Slugger();
  const blogSlug = `/advice/${blogPost.primaryBlogCategory?.title}/${blogPost.slug}`;
  const [expanded, setExpanded] = useState(false);
  const headings = blogPost.content?.childMdx?.headings?.filter(
    (h) => h?.depth == 2,
  );

  if (!headings?.length || headings.length < 3) return null;

  return (
    <TableOfContentsWrapper
      {...rest}
      pointer
      onClick={() => setExpanded((prev) => !prev)}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={expanded ? 16 : null}
      >
        <Body variant={1} medium>
          Table of Contents
        </Body>
        <Circle small backgroundColor={colors.white}>
          <Icon
            name={expanded ? IconName.ChevronUp : IconName.ChevronDown}
            color={colors.navyBlue}
          />
        </Circle>
      </Flex>

      {expanded &&
        headings.map((heading, idx) => {
          const headingValueWithContentVariablesReplaced = replaceContentVariables(
            heading?.value,
            contentVariables,
          );

          return (
            <Flex alignItems="center" key={idx} marginBottom={4}>
              &bull;
              <Link
                marginLeft={4}
                underline
                variant={3}
                to={normalizeUrl(
                  `${blogSlug}#${slugger.slug(
                    headingValueWithContentVariablesReplaced || '',
                  )}`.toLowerCase(),
                )}
              >
                {headingValueWithContentVariablesReplaced}
              </Link>
            </Flex>
          );
        })}
    </TableOfContentsWrapper>
  );
};
