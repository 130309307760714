import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import {
  BlogPostHeadingFragmentFragment as BlogPostHeadingFragmentTypes,
  ContentVariablesFragment,
} from 'graphqlTypes';
import { replaceContentVariables } from 'helpers/replaceContentVariables';
import { titleize } from 'helpers/titleize';
import React, { FC } from 'react';

export const BlogPostHeadingFragment = graphql`
  fragment BlogPostHeadingFragment on ContentfulBlogPost {
    title
    primaryBlogCategory {
      title
    }
    excerpt {
      childMdx {
        body
      }
      excerpt
    }
  }
`;

interface BlogPostHeadingProps {
  blogPost: BlogPostHeadingFragmentTypes;
  contentVariables: ContentVariablesFragment[];
}

export const BlogPostHeading: FC<BlogPostHeadingProps> = ({
  blogPost,
  contentVariables,
}) => {
  const { title, excerpt, primaryBlogCategory } = blogPost;

  return (
    <Flex column marginBottom={24}>
      <Body medium variant={5} color={colors.forestGreen} marginBottom={12}>
        {titleize(primaryBlogCategory?.title || '')}
      </Body>
      <Subheading variant={1} marginBottom={16}>
        {replaceContentVariables(title, contentVariables)}
      </Subheading>
      {excerpt?.childMdx?.body && (
        <Body variant={2}>
          <MDXRenderer>
            {replaceContentVariables(
              excerpt?.childMdx.body,
              contentVariables,
            ) || ''}
          </MDXRenderer>
        </Body>
      )}
    </Flex>
  );
};
